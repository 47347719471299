var $globalHeader = $(".global-header");

const hotlineMapping = new MappingListener({
	selector: ".hotline-wrapper",
	mobileWrapper: ".menu-wrapper",
	mobileMethod: "appendTo",
	desktopWrapper: ".menu-toggle",
	desktopMethod: "insertBefore",
	breakpoint: 1025,
}).watch();

// $(".search-toggle").on("click", function(e){
// 	e.preventDefault()
// 	$(".search-popup").fadeIn()
// 	$(".search-backdrop").fadeIn()
// 	$(".searchinput").focus()
// 	$("body").addClass("overflow-hidden");
// })

$(".search-backdrop").on("click", function(e){
	e.preventDefault()
	$(".search-popup").fadeOut()
	$(".search-backdrop").fadeOut()
	$(".searchinput").trigger("change")
	$(".searchinput").val("")
	$("body").removeClass("overflow-hidden");
})

$(".menu-toggle").on("click", function () {
	$(".menu-wrapper").slideToggle();
});

$globalHeader.sticky({
	zIndex: 50,
	topSpacing: 0,
	className: 'sticky-header',
});
