$(".key-item").each(function (index) {
	let _this = $(this);
	_this.mouseenter(function () {
		$(".key-background-item").eq(index).addClass("show");
	});
	_this.mouseleave(function () {
		$(".key-background-item").eq(index).removeClass("show");
	});
	
});
