function checkHasOnlyASlide(node) {
	const slide = node;
	if (!slide.$el) return;
	const countSlide = $(slide.$el).attr("data-count-slide");
	if (countSlide > 1) return;
	slide.destroy();
}
// Slide
const sharedBannerOptions = {
	speed: 1700,
	loop: true,
	autoplay: {
		delay: 4000,
	},
};

const bannerHomeSlider = new Swiper(".home-banner-slider .swiper-container", {
	navigation: {
		prevEl: ".home-banner-slider .swiper-prev",
		nextEl: ".home-banner-slider .swiper-next",
	},
	speed: 1000,
	loop: true,
	autoplay: {
		delay: 4000,
	},
	cssMode: true,
});

const pageBannerSlide = new Swiper(".section-page-banner .swiper-container", {
	navigation: {
		prevEl: ".section-page-banner .swiper-prev",
		nextEl: ".section-page-banner .swiper-next",
	},
	speed: 1000,
	loop: true,
	autoplay: {
		delay: 4000,
	},
});
checkHasOnlyASlide(bannerHomeSlider);
checkHasOnlyASlide(pageBannerSlide);
const otherNewsSlide = new Swiper(".news-detail-other-news .swiper-container", {
	navigation: {
		prevEl: ".section-page-banner .swiper-prev",
		nextEl: ".section-page-banner .swiper-next",
	},
	speed: 1000,
	slidesPerView: 3,
	spaceBetween: 32,
});
