var lazyLoadInstance = new LazyLoad({
	// Your custom settings go here
});

window.lazyLoadInstance = lazyLoadInstance;

(function () {
	var src = "//cdn.jsdelivr.net/npm/eruda";
	if (
		!/eruda=true/.test(window.location) &&
		localStorage.getItem("active-eruda") != "true"
	) {
		return;
	}
	document.write("<scr" + 'ipt src="' + src + '"></scr' + "ipt>");
	document.write("<scr" + "ipt>eruda.init();</scr" + "ipt>");
})();

import { CountUp } from "countup.js";
import "./partials/Header";
import "./partials/Slider";
import "./partials/Home";

jQuery(function () {
	addFirstColumnClass();

	$(window).resizable({
		resizeend: function () {
			addFirstColumnClass();
		},
	});

	$(".counter").each(function (index, element) {
		let count = parseInt($(element).data("count"));
		const countUp = new CountUp(element, count, {
			enableScrollSpy: true,
			scrollSpyOnce: true,
		});
		if (!countUp.error) {
			countUp.start();
		} else {
			console.error(countUp.error);
		}
	});

	$(".scroll-mouse").on("click", function () {
		scrollToElement($(this).attr("href"));
	});

	$(".menu a").each(function () {
		let id = $(this).attr("href");
		$(this).on("click", function () {
			scrollToElement(id);
		});
	});
	var header = document.querySelector(".global-header");
	var spy = new Gumshoe(".menu a", {
		events: true,
		offset: function () {
			return header.getBoundingClientRect().height + 32;
		},
	});
});

function addFirstColumnClass() {
	const row = $(".row-computed");
	let currentTopPosition = null;

	row.find("> *")
		.removeClass("first-column")
		.each(function () {
			const itemTopPosition = $(this).position().top;

			if (
				currentTopPosition === null ||
				itemTopPosition > currentTopPosition
			) {
				currentTopPosition = itemTopPosition;
				$(this).addClass("first-column");
			}
		});
}

var scrollToElement = function (el, ms) {
	var speed = ms ? ms : 700;
	$("html,body").animate(
		{
			scrollTop: $(el).offset().top - $("header").outerHeight(),
		},
		speed
	);
};

window.scrollToElement = scrollToElement;
